// @flow
import React from 'react';
import { Link } from 'gatsby';
import styles from './Menu.module.scss';

type Props = {
  menu: {
    label: string,
    path: string
  }[]
};

const Menu = ({ menu }: Props) => (
  <nav className={styles['menu']}>
	{/* <input className="menu-btn" type="checkbox" id="menu-btn" />
	<label className="menu-icon" for="menu-btn"><span className="navicon"></span></label> */}
    <ul className={styles['menu__list']}>
      {menu.map((item) => (
        <li className={styles['menu__list-item']} key={item.path}>
          <Link
            to={item.path}
            className={styles['menu__list-item-link']}
            activeClassName={styles['menu__list-item-link--active']}
          >
            {item.label}
          </Link>
		  {/* <ul className={styles['menu__list']}>
			{item.submenu && item.submenu.map((subitem) => (
				<li className={styles['menu__list-item']} key={subitem.path}>
				<Link
					to={subitem.path}
					className={styles['menu__list-item-link']}
					activeClassName={styles['menu__list-item-link--active']}
				>
					{subitem.label}
				</Link>
				</li>
			))}
			</ul> */}
        </li>
      ))}
    </ul>
  </nav>
);

export default Menu;
